import {memo, useState, useEffect} from 'react';
import {useLoaderData, useNavigate, useParams} from '@remix-run/react';

import type {
  Cart as CartType,
  UserError,
} from '@shopify/hydrogen/storefront-api-types';

import {
  Apparel,
  Hero,
  LatestGraphics,
  MxEquipments,
  MxGraphics,
  RecommendedProducts,
  SaddleCovers,
} from 'ui';

import useTranslate from '~/hooks/useTranslate';
import {getSession, commitSession} from '~/utils/sessions.server';
import type {GeolocationData} from '~/lib/type';

import {
  ActionArgs,
  LinksFunction,
  LoaderArgs,
  V2_MetaFunction,
  json,
  redirect,
} from '@shopify/remix-oxygen';
import {
  LATEST_PRODUCTS_QUERY,
  LATEST_PRODUCTS_QUERY_INTERNATIONAL,
} from '~/queries/product';

// @ts-ignore
import style1 from 'swiper/css';
// @ts-ignore
import style2 from 'swiper/css/navigation';
// @ts-ignore
import style3 from 'swiper/css/pagination';
// @ts-ignore
import style4 from 'swiper/css/scrollbar';

import {LanguagePicker} from '~/components/languagePicker';
import {redirectFunction} from '~/lib/utils';
import {UPDATE_CART_BUYER_COUNTRY} from '~/mutations/buyer';
import {metaFunction} from '~/utils/seo';
import {PAGE_SEO_QUERY} from '~/queries/page';

export const links: LinksFunction = () => [
  {rel: 'stylesheet', href: style1},
  {rel: 'stylesheet', href: style2},
  {rel: 'stylesheet', href: style3},
  {rel: 'stylesheet', href: style4},
];

export const meta: V2_MetaFunction = metaFunction;

export async function loader({request, params, context}: LoaderArgs) {
  const {products} =
    params.locale === 'ro'
      ? await context.storefront.query(LATEST_PRODUCTS_QUERY, {
          cache: {mode: 'no-store'},
          headers: {
            'Accept-Language': params.locale as string,
          },
        })
      : await context.storefront.query(LATEST_PRODUCTS_QUERY_INTERNATIONAL, {
          cache: {mode: 'no-store'},
          headers: {
            'Accept-Language': params.locale as string,
          },
        });

  const latestGraphics = products?.nodes
    .filter((product: any) => product.tags.includes('mx-graphics'))
    .slice(0, 6);

  const recommendedProducts = products?.nodes
    ?.filter((product: any) => !product.tags.includes('mx-graphics'))
    .slice(0, 6);
  const session = await getSession(request.headers.get('Cookie'));
  const locale: string = session.get('locale') || undefined;
  const language = request.headers.get('accept-language');
  const cartId = await context.session.get('cartId');
  const pageData = await context.storefront.query(PAGE_SEO_QUERY);

  return json({
    request,
    latestGraphics,
    recommendedProducts,
    locale,
    products,
    language,
    cartId,
    pageData,
  });
}

export async function action({request, context, params}: ActionArgs) {
  const session = await getSession(request.headers.get('Cookie'));
  const formData = await request.formData();
  const locale = formData.get('locale') ?? '';
  const path = formData.get('path') ?? '';
  session.set('locale', locale);
  const cookies = await commitSession(session);

  const buyerIdentity = {
    countryCode:
      locale === 'en'
        ? 'DE'
        : `${(locale as string).charAt(0).toUpperCase()}${(locale as string)
            .charAt(1)
            .toUpperCase()}`,
  };
  const cartId = await context.session.get('cartId');

  if (cartId)
    await context.storefront.mutate<{
      cartBuyerIdentityUpdate: {cart: CartType; errors: UserError[]};
    }>(UPDATE_CART_BUYER_COUNTRY, {
      variables: {
        cartId: `${cartId}`,
        buyerIdentity,
      },
    });

  if (path) {
    return redirect(path.toString(), {
      headers: {
        'Set-Cookie': cookies,
      },
    });
  }

  return redirect(locale.toString() + '/', {
    headers: {
      'Set-Cookie': cookies,
    },
  });
}

const App = memo(() => {
  const {cartId, latestGraphics, recommendedProducts, products, locale} =
    useLoaderData<typeof loader>();
  const params = useParams();
  const navigate = useNavigate();

  const [countryCode, setCountryCode] = useState<string>('');
  const [countryName, setCountryName] = useState<string>('');

  useEffect(() => {
    if (locale && !params.locale) {
      navigate(`${locale}/`);
    }
  }, [locale]);

  useEffect(() => {
    const getUserRegion = async () => {
      try {
        const response = await fetch(
          'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en',
        );
        const data: GeolocationData = await response.json();
        setCountryCode(data.countryCode.toLowerCase());
        setCountryName(data.countryName);
      } catch (error) {
        console.error(error);
      }
    };
    getUserRegion();
  }, []);

  return (
    <>
      <Hero />
      <MxGraphics />
      <SaddleCovers />
      <MxEquipments locale={locale} />
      <Apparel />
      <div className="px-8 bg-white">
        <LatestGraphics products={latestGraphics} />
        <RecommendedProducts products={recommendedProducts} />
      </div>
      {!locale && (
        <LanguagePicker countryCode={countryCode} countryName={countryName} />
      )}
    </>
  );
});

export default App;
