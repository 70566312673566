import {Transition, Dialog} from '@headlessui/react';
import {Form} from '@remix-run/react';
import {Fragment, useState} from 'react';
import logo from 'public/logo.png';

export const LanguagePicker = ({
  countryName,
  countryCode,
  image,
}: {
  countryName: string;
  countryCode: string;
  image?: string;
}) => {
  let [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-8 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden bg-white px-8 py-6 text-left align-middle shadow-xl transition-all lg:px-16 lg:py-12">
                  <div className="flex justify-center">
                    <img src={logo} />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="font-poppins mt-6 text-center text-2xl leading-6 text-gray-900 lg:text-[32px] lg:leading-8"
                  >
                    Welcome to Let's Ride Graphics!
                  </Dialog.Title>
                  <div className="flex justify-center">
                    <div className="font-montserrat mt-2 flex w-fit flex-col items-center text-sm">
                      <p className="text-center text-sm">
                        You are visiting us from {countryName}, would you like
                        to go to our{' '}
                        {countryName === 'Romania'
                          ? countryName
                          : 'International'}{' '}
                        website?
                      </p>
                      <Form
                        action="/"
                        className="w-full"
                        noValidate
                        method="post"
                      >
                        <input
                          type="hidden"
                          id="locale"
                          name="locale"
                          value={countryCode === 'ro' ? countryCode : 'en'}
                        ></input>
                        <button
                          type="submit"
                          onClick={() => setIsOpen(false)}
                          className="bg-neongreen mt-4 w-full border py-2 outline-none hover:text-white lg:mt-8 lg:py-4"
                        >
                          Go to{' '}
                          {countryName === 'Romania'
                            ? countryName
                            : 'International'}
                        </button>
                      </Form>
                      {countryCode === 'ro' && (
                        <Form
                          action="/"
                          className="w-full"
                          noValidate
                          method="post"
                        >
                          <input
                            type="hidden"
                            id="locale"
                            name="locale"
                            value={'en'}
                          ></input>
                          <button
                            onClick={() => setIsOpen(false)}
                            className="mt-2 w-full border border-black bg-black py-2 text-white lg:mt-4 lg:py-4"
                          >
                            International
                          </button>
                        </Form>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
